@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.character-card {
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
  overflow: hidden;
}

.animate-marquee-horizontal {
  animation: marquee 160s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .animate-marquee-horizontal {
    animation: none;
  }
}

.rounded-dribbble {
  border-radius: 20px;
}

.character-card {
  transition: transform 0.3s ease-out;
}

.character-card:hover {
  transform: translateY(-5px);
}

.character-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
  border-radius: 20px;
}

.character-card h3 {
  position: relative;
  z-index: 10;
}

.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

.overflow-x-auto::-webkit-scrollbar {
  height: 6px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: transparent;
}

.character-cards-container {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fixed.inset-0.z-10 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

/* Optimize for iOS */
@supports (-webkit-touch-callout: none) {
  .character-cards-container {
    -webkit-overflow-scrolling: touch;
  }
  
  .animate-marquee-horizontal {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}