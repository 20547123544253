@tailwind base;
@tailwind components;
@tailwind utilities;

.text-pretty{
    text-wrap: balance;
}

body.carousel-open .whatsapp-button {
    display: none !important;
  }